/* global gtag fbq */
import {APPS} from '@renper/lib/constants.mjs';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {FaCheckCircle} from 'react-icons/fa';

import {
  BASE_TITLE,
  CHECKOUT_COOKIE_KEY,
  EMAIL_APP_SUPPORT,
  LS_KEY_REFERRAL,
  PLATFORM,
  PURCHASE_TYPE,
} from '../constants.js';
import {AuthPropType} from '../propTypes.js';
import {COLOR_GRAY_SECONDARY, COLOR_RP_RED, SPACE} from '../styles.js';
import history from '../utils/history.js';
import log from '../utils/log.js';
import {checkout} from '../utils/payments.js';
import {getProducts} from '../utils/products.js';
import {recordReferralCode} from '../utils/recordReferralCode.js';
import {clearReferralCode, sanitizeReferralCode} from '../utils/referrals.js';
import storage from '../utils/storage.js';
import User from '../utils/User.js';
import ActiveSubs from './ActiveSubs.jsx';
import Button from './Button.jsx';
import Content from './Content.jsx';
import ErrorMessage from './ErrorMessage.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import Page from './Page.jsx';
import PageTitle from './PageTitle.jsx';
import SpinnerIcon from './SpinnerIcon.jsx';
import Subscriptions from './Subscriptions.jsx';
import TextInput from './TextInput.jsx';

export default class Subscribe extends Component {
  static propTypes = {
    auth: AuthPropType,
    app: PropTypes.oneOf(Object.values(APPS)),
  };

  state = {
    referralCode: null,
    referralCodeIsUserEntered: false,
    referralIsHidden: false,
    referralErrorMessage: null,
    isLoadingReferral: false,
    showReferralForm: false,

    selectedIapPlatformId: null,

    products: [],
    productsErrorMessage: null,
    isLoadingProducts: true,
    isSubscribingTo: null,

    showExtraPlans: false,
    shouldAutoCheckout: false,
  };

  async componentDidMount() {
    let user = await User.getAndPersist();
    if (user) {
      try {
        // Always make sure this page is up to date when it loads!
        user = await this.props.auth.refresh();
      } catch (err) {
        if (err.status === 401) {
          this.props.auth.logout();

          return;
        }

        log.notify(err);
      }
    }

    // Validate referral code in local storage
    const referralCode = storage.getItem(LS_KEY_REFERRAL);
    if (referralCode) {
      try {
        const {isHidden} = await recordReferralCode(referralCode);
        // TODO: we should track if this code was entered by the user in an anonymous
        // session or not so that, if they login after entering this code, we can call
        // enterReferralCode with useEntered = true with associateOnly true to properly
        // record this entry for the user who now have identified after login.
        this.setState({referralCode, referralIsHidden: isHidden});
      } catch (err) {
        this.setState({showReferralForm: true, referralCode, referralErrorMessage: err.message});
      }
    }
    fbq('track', 'ViewContent', {content_name: `Subscribe (${this.props.app})`});
    await this.loadProducts();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.shouldAutoCheckout === true && prevState.shouldAutoCheckout === false) {
      this.handleSubscribe();
    }
  }

  async loadProducts(retry = 0) {
    this.setState({isLoadingProducts: true}, async () => {
      const user = User.get();

      try {
        const apps = this.props.app === APPS.training ? [APPS.training] : [APPS.diet];
        const products = await getProducts(apps, this.state.referralCode);
        if (!products.length) {
          this.setState({
            isLoadingProducts: false,
            productsErrorMessage: 'There are no available plans at this time',
          });

          return;
        }
        // Reset selected iap selection
        let selectedIapPlatformId = null;
        let shouldAutoCheckout = false;

        const currentUrl = new URL(window.location.href);
        const productKey = currentUrl.searchParams.get('product');
        if (productKey) {
          selectedIapPlatformId = products.find(p => p.key === productKey)?.inAppPurchases[0]
            .platformId;
          shouldAutoCheckout = selectedIapPlatformId && !this.state.referralErrorMessage;
          currentUrl.searchParams.delete('product');
          history.replace(currentUrl.toString(), null);
        }

        // Preselect the first available other purchase if the user doesn't have any
        // active subscriptions that aren't cancelled yet
        if (
          !selectedIapPlatformId &&
          !user?.activeSubscriptions.filter(
            sub => sub.access.includes(this.props.app) && !sub.cancellationDate
          ).length
        ) {
          selectedIapPlatformId = products[0].inAppPurchases[0].platformId;
        }

        this.setState({
          products,
          isLoadingProducts: false,
          selectedIapPlatformId,
          productsErrorMessage: null,
          shouldAutoCheckout,
        });
      } catch (err) {
        if (err instanceof TypeError && err.message === 'cancelled' && retry < 3) {
          return this.loadProducts(retry + 1);
        } else {
          log.notify(err);
          this.setState({
            isLoadingProducts: false,
            productsErrorMessage: 'There was a problem loading plans. Please try again later.',
          });
        }
      }
    });
  }

  handleReferralCodeChange = event => {
    event.preventDefault();

    this.setState({
      referralCode: sanitizeReferralCode(event.target.value),
      referralIsHidden: false,
    });
  };

  clearReferralCode = () => {
    this.setState(
      {
        referralCode: null,
        referralIsHidden: false,
        referralErrorMessage: null,
        isLoadingReferral: false,
        showReferralForm: false,
      },
      () => {
        clearReferralCode();
        this.loadProducts().catch(log.notify);
      }
    );
  };

  handleReferralSubmit = () => {
    const referralCode = sanitizeReferralCode(this.state.referralCode);

    this.setState({referralCodeIsUserEntered: true, isLoadingReferral: true}, async () => {
      try {
        await recordReferralCode(referralCode, true);

        this.setState(
          {isLoadingReferral: false, referralErrorMessage: '', showReferralForm: false},
          () => this.loadProducts().catch(log.notify)
        );
      } catch (err) {
        clearReferralCode();
        this.setState({isLoadingReferral: false, referralErrorMessage: err.message});
      }
    });
  };

  handleSubscribe = async (isAnonymousFree = false) => {
    if (isAnonymousFree) {
      alert(`You can't redeem free passes while signed out. Please sign in first.`);

      return;
    }

    const platformId = this.state.selectedIapPlatformId;
    const product = this.state.products.find(p =>
      p.inAppPurchases.find(iap => iap.platformId === platformId)
    );

    if (this.state.isSubscribingTo || !this.state.selectedIapPlatformId) {
      return;
    }

    const user = User.get();

    const activeTypedAccessPasses = user
      ? user.activeSubscriptions.filter(
          sub =>
            sub.iapPurchaseType === PURCHASE_TYPE.accessPass &&
            sub.platform === PLATFORM.rp &&
            sub.access.includes(this.props.app)
        )
      : [];

    const activeTypedInHouseSub = user
      ? user.activeSubscriptions.find(
          sub =>
            sub.iapPurchaseType === PURCHASE_TYPE.subscription &&
            sub.platform === PLATFORM.rp &&
            sub.access.includes(this.props.app) &&
            !sub.cancellationDate
        )
      : null;

    if (gtag) {
      gtag('event', 'rp-click-subscribe', {
        event_category: 'ecommerce',
      });
    }

    this.setState({isSubscribingTo: platformId}, async () => {
      const appName = this.props.app === APPS.training ? 'RP Hypertrophy' : 'RP Diet Coach';

      if (activeTypedAccessPasses.length) {
        log.notify(
          new Error('User encountering message warning to not subscribe with access pass')
        );
        const shouldProceed = confirm(
          'You already have an active access pass. Press OK to proceed.'
        );
        if (!shouldProceed) {
          this.setState({isSubscribingTo: null, shouldAutoCheckout: false});
          return;
        }
      } else if (activeTypedInHouseSub) {
        log.notify(new Error('User encountering message warning to not double subscribe'));
        const shouldProceed = confirm(
          `You already have an active ${appName} app subscription. Press OK to proceed.`
        );
        if (!shouldProceed) {
          this.setState({isSubscribingTo: null, shouldAutoCheckout: false});
          return;
        }
      }

      try {
        let iap = null;
        let price = null;
        for (const checkProduct of this.state.products) {
          const checkIap = checkProduct.inAppPurchases[0];
          if (checkIap.platformId === platformId) {
            iap = checkIap;
            price = iap.prices[0];
            break;
          }
        }

        const isEligibleForIntroPrice = this.getIsEligibleForIntroPrice(iap);
        const cents = (isEligibleForIntroPrice ? price.introPrice : null) ?? price.price;

        Cookies.set(
          CHECKOUT_COOKIE_KEY,
          JSON.stringify({
            platformId,
            purchaseType: iap.purchaseType,
            referralCode: this.state.referralCode,
            referralCodeIsUserEntered: this.state.referralCodeIsUserEntered,
            conversionValue: cents,
          }),
          {
            expires: 365, // days
            sameSite: 'lax',
            secure: true,
          }
        );

        fbq('track', 'InitiateCheckout', {
          content_category: iap.purchaseType,
          content_ids: [platformId],
          contents: [{id: platformId, quantity: 1}],
          currency: 'USD',
          num_items: 1,
          value: price.price / 100,
          isEligibleForIntroPrice,
          introPrice: price.introPrice,
          introLength: price.introLength,
          introUnit: price.introUnit,
          referralCode: this.state.referralCode,
        });

        await checkout(user, platformId, product);
      } catch (err) {
        if (err.status === 410) {
          alert(err.message);
          this.setState({
            isSubscribingTo: null,
            shouldAutoCheckout: false,
            referralErrorMessage: 'This referral code is no longer available.',
            showReferralForm: true,
          });

          return;
        }

        log.notify(err);
        this.setState({isSubscribingTo: null, shouldAutoCheckout: false}, () => {
          alert(
            `Sorry, something went wrong. Please try again or contact ${EMAIL_APP_SUPPORT} if the problem persists.`
          );
        });
      }
    });
  };

  getSelectedIapPurchaseType() {
    return this.state.products.find(
      product => product.inAppPurchases[0].platformId === this.state.selectedIapPlatformId
    )?.purchaseType;
  }

  getHasFreeTrial(priceRecord) {
    return priceRecord.introPrice === 0;
  }

  getHasPaidIntro(priceRecord) {
    return priceRecord.introPrice > 0;
  }

  getFullPricePeriodString(priceRecord) {
    return priceRecord.billingPeriod === 1
      ? priceRecord.unit
      : `${priceRecord.billingPeriod} ${priceRecord.unit}s`;
  }

  getIntroPricePeriodString(priceRecord) {
    return priceRecord.introBillingPeriod === 1
      ? priceRecord.introUnit
      : `${priceRecord.introBillingPeriod} ${priceRecord.introUnit}s`;
  }

  getIntroLengthString(priceRecord) {
    return priceRecord.introLength === 1
      ? priceRecord.introUnit
      : `${priceRecord.introLength} ${priceRecord.introUnit}s`;
  }

  getIsEligibleForIntroPrice(iap) {
    return !User.get()?.consumedIaps.find(
      consumedIap => consumedIap.subscriptionGroupId === iap.subscriptionGroup
    );
  }

  formatDollarPrice(cents) {
    if (cents === 0) {
      return 'Free';
    }

    return `$${cents / 100}`;
  }

  getProductPricingBodyLines(product) {
    const iap = product.inAppPurchases[0];
    const priceRecord = iap.prices[0];

    // NOTE: this is true for access passes using custom access_starts_at and access_ends_at
    // For now, they are on the hook for defining an iap.description
    if (!priceRecord.unit || !priceRecord.billingPeriod) {
      return [];
    }

    const activeSub = User.get()?.activeSubscriptions.find(
      sub => sub.iapPlatformId === iap.platformId
    );

    const isFree = priceRecord.price === 0;

    const fullPriceString = this.formatDollarPrice(priceRecord.price);
    const fullPricePeriodString = this.getFullPricePeriodString(priceRecord);

    const introPriceString = this.formatDollarPrice(priceRecord.introPrice);
    const introPricePeriodString = this.getIntroPricePeriodString(priceRecord);
    const introLengthString = this.getIntroLengthString(priceRecord);

    const bodyLines = [];

    const hasPaidIntro = this.getHasPaidIntro(priceRecord);
    const hasFreeTrial = this.getHasFreeTrial(priceRecord);
    const isEligibleForIntroPrice = this.getIsEligibleForIntroPrice(iap);

    if (!isFree) {
      if (hasPaidIntro && isEligibleForIntroPrice) {
        bodyLines.push(
          `${introPriceString} / ${introPricePeriodString} first ${introLengthString}`
        );
        bodyLines.push(`then ${fullPriceString} / ${fullPricePeriodString}`);
      } else {
        bodyLines.push(`${fullPriceString} / ${fullPricePeriodString}`);
      }

      if (hasFreeTrial) {
        if (isEligibleForIntroPrice) {
          bodyLines.push(`${priceRecord.introLength} ${priceRecord.introUnit} free trial`);
        }
      }
    } else {
      bodyLines.push(`${fullPriceString} for ${fullPricePeriodString}`);
    }

    if (!isEligibleForIntroPrice) {
      if (activeSub) {
        if (activeSub?.isFreeTrial) {
          bodyLines.push('In free trial');
        } else if (activeSub?.isIntroPriced) {
          bodyLines.push('Promo price active');
        }
      } else {
        if (hasPaidIntro) {
          bodyLines.push('Promo price already used');
        } else if (hasFreeTrial) {
          bodyLines.push('Free trial already used');
        }
      }
    }

    return bodyLines;
  }

  getAdditionalPurchasableProducts() {
    const user = User.get();

    const activeSubIapPlatformIds = {};
    if (user) {
      for (const activeSub of user.activeSubscriptions) {
        activeSubIapPlatformIds[activeSub.iapPlatformId] = true;
      }
    }

    return this.state.products.filter(product => {
      const iap = product.inAppPurchases[0];
      return !activeSubIapPlatformIds[iap.platformId];
    });
  }

  renderPurchasableProducts() {
    if (this.state.isLoadingProducts) {
      return (
        <div style={{paddingBottom: SPACE * 3}}>
          <SpinnerIcon />
        </div>
      );
    }

    if (this.state.productsErrorMessage) {
      return (
        <>
          <ErrorMessage message={this.state.productsErrorMessage} />
          {this.renderReferralForm()}
        </>
      );
    }

    const additionalPurchasableProducts = this.getAdditionalPurchasableProducts();

    const products = additionalPurchasableProducts.map(product => {
      const iap = product.inAppPurchases[0];
      let bodyLines = [];
      if (iap.description) {
        bodyLines = iap.description.split('\\n');
      }
      bodyLines = bodyLines.concat(this.getProductPricingBodyLines(product));

      const subscriptionTitle = iap.name.replace('\\n', ' ');
      // TODO: disabling this for now. We should be using iap.callout and iap.description
      // to make users see and feel that their referral code did something
      // Adding the code in parenthesis makes the title too long and hard to read. Adds disfluency
      // const matchedReferralCode = product.matchedReferralCodes[0];
      // if (!this.state.referralIsHidden && matchedReferralCode) {
      //   subscriptionTitle += ` (${matchedReferralCode})`;
      // }

      const isSelected = this.state.selectedIapPlatformId === iap.platformId;

      return (
        <Subscriptions.Box
          key={product.id}
          onClick={() => {
            this.setState({selectedIapPlatformId: iap.platformId});
            fbq('track', 'CustomizeProduct', {iapPlatformId: iap.platformId});
          }}
          isSelected={isSelected}
          style={{marginTop: iap.callout ? SPACE * 3 : null}}
        >
          {iap.callout ? (
            <Subscriptions.BoxCallout isSelected={isSelected} isClickable>
              {iap.callout}
            </Subscriptions.BoxCallout>
          ) : null}
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{flex: 1}}>
              <div style={this.state.isSubscribingTo ? {opacity: 0.5} : null}>
                <Subscriptions.BoxHeader>
                  <Subscriptions.Title isSelected={isSelected}>
                    {subscriptionTitle}
                  </Subscriptions.Title>
                </Subscriptions.BoxHeader>
                {bodyLines.map(line => (
                  <Subscriptions.BodyText isSelected={isSelected} key={line}>
                    {line}
                  </Subscriptions.BodyText>
                ))}
              </div>
            </div>
            <div
              style={{
                alignSelf: 'flex-start',
                marginRight: -SPACE,
                paddingLeft: SPACE,
                width: 26,
              }}
            >
              {isSelected ? <FaCheckCircle size={22} color={COLOR_RP_RED} /> : null}
            </div>
          </div>
        </Subscriptions.Box>
      );
    });

    const isFree =
      this.state.products.find(
        product => product.inAppPurchases[0].platformId === this.state.selectedIapPlatformId
      )?.inAppPurchases[0].prices[0].price === 0;

    const isAnonymousFree = isFree && !this.props.auth.user;

    let buttonText = 'Subscribe';
    if (this.getSelectedIapPurchaseType() === PURCHASE_TYPE.accessPass) {
      if (isFree) {
        buttonText = 'Redeem';
      } else {
        buttonText = 'Purchase';
      }
    }

    return (
      <Subscriptions.Container>
        {products}
        {this.renderReferralForm()}
        <Button
          variant="solid"
          color={COLOR_RP_RED}
          isLoading={this.state.isLoadingReferral}
          loadingContent={<SpinnerIcon size={22} />}
          onClick={() => this.handleSubscribe(isAnonymousFree)}
          style={{marginTop: SPACE * 3, width: '100%', fontSize: 20}}
          isDisabled={!this.state.selectedIapPlatformId}
        >
          {buttonText}
        </Button>
      </Subscriptions.Container>
    );
  }

  renderReferralForm() {
    if (!this.state.showReferralForm) {
      const buttonText =
        this.state.referralCode && !this.state.referralIsHidden
          ? `Referral Code: ${sanitizeReferralCode(this.state.referralCode)}`
          : 'Have a referral code?';

      return (
        <Button
          variant="link"
          color={COLOR_GRAY_SECONDARY}
          onClick={() => this.setState({showReferralForm: true})}
          style={{marginTop: -(SPACE * 2)}}
        >
          {buttonText}
        </Button>
      );
    }

    return (
      <div style={{width: '100%'}}>
        {this.state.referralErrorMessage && (
          <ErrorMessage message={this.state.referralErrorMessage} />
        )}
        <TextInput
          autoFocus
          type="text"
          placeholder="Referral code"
          value={!this.state.referralIsHidden ? this.state.referralCode || '' : ''}
          onChange={this.handleReferralCodeChange}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingBottom: SPACE * 4,
          }}
        >
          <Button
            variant="link"
            color={COLOR_RP_RED}
            onClick={this.clearReferralCode}
            style={{marginTop: SPACE * 3}}
          >
            {!this.state.referralIsHidden && this.state.referralCode ? 'Clear code' : 'Cancel'}
          </Button>
          <Button
            variant="solid"
            color={COLOR_RP_RED}
            isLoading={this.state.isLoadingReferral}
            loadingContent={<SpinnerIcon size={22} />}
            onClick={this.handleReferralSubmit}
            style={{marginTop: SPACE * 3}}
            isDisabled={!this.state.referralCode}
          >
            Apply code
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const user = User.get();

    document.title = `${BASE_TITLE} - Subscribe`;

    if (this.state.shouldAutoCheckout) {
      return null;
    }

    const subscriptionsForType = user
      ? user.activeSubscriptions.filter(sub => sub.access.includes(this.props.app))
      : [];

    const title =
      this.props.app === 'diet' ? 'My RP Diet Coach app plans' : 'My RP Hypertrophy app plans';

    const additionalPurchasableProducts = this.getAdditionalPurchasableProducts();

    const yourPlans = subscriptionsForType.length ? (
      <>
        <PageTitle title={title} />
        <ActiveSubs
          activeSubs={subscriptionsForType}
          auth={this.props.auth}
          selectedIapPlatformId={this.state.selectedIapPlatformId}
        />
        {!this.state.showExtraPlans && !!additionalPurchasableProducts.length && (
          <Button
            variant="link"
            color={COLOR_GRAY_SECONDARY}
            onClick={() => this.setState({showExtraPlans: true})}
            style={{marginTop: -(SPACE * 2)}}
          >
            Choose another plan
          </Button>
        )}
      </>
    ) : null;

    let subscribe = null;
    if (subscriptionsForType.length) {
      if (!this.state.showExtraPlans) {
        subscribe = null;
      } else {
        subscribe = (
          <>
            <PageTitle title="Choose another plan" />
            {this.renderPurchasableProducts()}
          </>
        );
      }
    } else {
      subscribe = (
        <>
          <PageTitle title="Choose a plan" />
          {this.renderPurchasableProducts()}
        </>
      );
    }

    return (
      <>
        <Header auth={this.props.auth} />
        <Content>
          <Page>
            {yourPlans}
            {subscribe}
          </Page>
        </Content>
        <Footer auth={this.props.auth} />
      </>
    );
  }
}
