import {ErrorWithExtraData} from '../errors.mjs';
import {isNum} from '../math.mjs';
import {
  BODYWEIGHT_MAX,
  EXERCISE_TYPES,
  MAX_MESO_MICROS,
  MIN_MESO_MICROS,
  REC_BODYWEIGHT_LOADING_RANGES,
  REC_WEIGHT_RANGES,
  SKIPPED_SET_REPS,
  STATUSES,
  STATUSES_FINISHED,
  STATUSES_IN_PROGRESS,
  UNIT,
} from './constants.mjs';
import {MalformedRangeObjectError} from './errors.mjs';

export function getRangeMinMax(ranges) {
  let min = null;
  let max = null;

  for (const range of ranges) {
    if (!('start' in range) || !('end' in range) || !('increment' in range)) {
      throw new MalformedRangeObjectError({range, ranges});
    }

    const firstVal = range.start;
    const lastVal = range.end - range.increment;

    if (min === null || firstVal < min) {
      min = range.start;
    }
    if (max === null || lastVal > max) {
      max = lastVal;
    }
  }

  return {min, max};
}

export function getIncrements(ranges) {
  const increments = new Set();
  for (const range of ranges) {
    if (!('start' in range) || !('end' in range) || !('increment' in range)) {
      throw new MalformedRangeObjectError({range, ranges});
    }

    for (let i = range.start; i < range.end; i += range.increment) {
      increments.add(i);
    }
  }
  return Array.from(increments).sort((a, b) => a - b);
}

export function getRequiresBodyweight(exerciseType) {
  return [
    EXERCISE_TYPES.machineAssistance,
    EXERCISE_TYPES.bodyweightLoadable,
    EXERCISE_TYPES.bodyweightOnly,
  ].includes(exerciseType);
}

export function getMinMaxWeight(exerciseType, bodyweight, unit) {
  if (exerciseType === EXERCISE_TYPES.bodyweightOnly) {
    return {min: bodyweight, max: bodyweight};
  } else if (exerciseType === EXERCISE_TYPES.bodyweightLoadable) {
    const {min, max} = getMinMaxAddedLoad(unit);
    return {
      min: bodyweight + min,
      max: bodyweight + max,
    };
  } else if (exerciseType === EXERCISE_TYPES.machineAssistance) {
    const {min, max} = getMinMaxAssistance(bodyweight, unit);
    return {
      min: bodyweight - max,
      max: bodyweight - min,
    };
  } else {
    return getRangeMinMax(REC_WEIGHT_RANGES[unit][exerciseType]);
  }
}

export function getMinMaxAddedLoad(unit) {
  return getRangeMinMax(REC_BODYWEIGHT_LOADING_RANGES[unit]);
}

export function getMinMaxAssistance(bodyweight, unit) {
  const minIncrement = unit === UNIT.lb ? 0.5 : 0.25; // Don't allow 0 total load because that would be infinity RIR!
  const maxAssistanceForBodyweight = Math.min(bodyweight, BODYWEIGHT_MAX[unit]) - minIncrement;
  return {min: 0, max: maxAssistanceForBodyweight};
}

export function getCurrentMesoKey(mesocycles) {
  const unfinishedMesos = mesocycles?.filter(
    m => !m.finishedAt && !STATUSES_FINISHED.meso.includes(m.status)
  );

  let mesoKey = null;
  if (mesocycles?.length) {
    let lastFinishedAt = null;
    for (const meso of unfinishedMesos) {
      const mesoLastFinishedAt = [meso.lastFinishedAtDay, meso.lastFinishedAtSet]
        .filter(Boolean)
        .sort()
        .reverse()?.[0];
      if (mesoLastFinishedAt) {
        if (lastFinishedAt === null || mesoLastFinishedAt > lastFinishedAt) {
          mesoKey = meso.key;
          lastFinishedAt = mesoLastFinishedAt;
        }
      }
    }

    // If no mesos have anything finishedAt, use the earliest meso (meso is sorted DES by created_at)
    if (!mesoKey) {
      mesoKey = unfinishedMesos?.[unfinishedMesos.length - 1]?.key;
    }
  }

  return mesoKey;
}

export function getAppUrl(envString) {
  switch (envString) {
    case 'production':
      return 'https://training.rpstrength.com';
    case 'development':
      return 'https://training.dev.rpstrength.com';
    case 'local':
      return 'http://localhost:9090';
    default:
      return `https://training.${envString}.rpstrength.app`;
  }
}

export function isValidYoutubeId(inputString) {
  const regex = /^[A-Za-z0-9_-]{11}$/;
  return regex.test(inputString);
}

export function extractYoutubeId(inputString) {
  const regex = /(youtu\.be\/|watch\?v=|shorts\/)([A-Za-z0-9_-]{11})/g;
  const match = regex.exec(inputString);
  return match ? match[2] : null;
}

const STATUSES_EXERCISES_WORK_PERFORMED = [
  STATUSES.exercise.complete,
  STATUSES.exercise.partial,
  STATUSES.exercise.pendingFeedback,
  STATUSES.exercise.started,
];

export function someDayExercisesWorkPerformed(dayExercisesWithStatuses) {
  return dayExercisesWithStatuses.some(dex =>
    STATUSES_EXERCISES_WORK_PERFORMED.includes(dex.status)
  );
}

export function dayMuscleGroupExercisesDoneAndWorked(dmgExercisesWithStatuses) {
  const finishedEmptyOrPendingFeedbackStatuses = [
    ...STATUSES_FINISHED.exercise,
    STATUSES.exercise.empty,
    STATUSES.exercise.pendingFeedback,
  ];

  const exercisesComplete = dmgExercisesWithStatuses.every(dex =>
    finishedEmptyOrPendingFeedbackStatuses.includes(dex.status)
  );

  return someDayExercisesWorkPerformed(dmgExercisesWithStatuses) && exercisesComplete;
}

// We need jointpain when an exercise is pendingFeedback
function getDayMuscleGroupExerciseNeedsJointPain(dmgExercisesWithStatuses) {
  return dmgExercisesWithStatuses.find(dex => dex.status === STATUSES.exercise.pendingFeedback);
}

// We need soreness when a some work has been done for a dmg dex and soreness IS NULL
export function dayMuscleGroupNeedsSoreness(dmg, dmgExercisesWithStatuses) {
  return someDayExercisesWorkPerformed(dmgExercisesWithStatuses) && dmg.soreness === null;
}

// We need pump if all exercises are done and worked and pump IS NULL
export function dayMuscleGroupNeedsPump(dmg, dmgExercisesWithStatuses) {
  return dayMuscleGroupExercisesDoneAndWorked(dmgExercisesWithStatuses) && dmg.pump === null;
}

// We need workload if all exercises are done and worked and workload IS NULL
export function dayMuscleGroupNeedsWorkload(dmg, dmgExercisesWithStatuses) {
  return dayMuscleGroupExercisesDoneAndWorked(dmgExercisesWithStatuses) && dmg.workload === null;
}

// Given a mesoDay, look for a musclegroup that is pendingFeedback, and check which
// values it (and its related exercises) needs feedback on
// NOTE: muscle groups must already have a status
export function getFeedbackData(mesoDayWithStatuses) {
  let result;

  for (const muscleGroup of mesoDayWithStatuses.muscleGroups) {
    if (muscleGroup.status === STATUSES.muscleGroup.pendingFeedback) {
      const exercisesForGroup = mesoDayWithStatuses.exercises.filter(
        ex => ex.muscleGroupId === muscleGroup.muscleGroupId
      );

      const needsJointPainDex = getDayMuscleGroupExerciseNeedsJointPain(exercisesForGroup);
      const needsSoreness = dayMuscleGroupNeedsSoreness(muscleGroup, exercisesForGroup);
      const needsPump = dayMuscleGroupNeedsPump(muscleGroup, exercisesForGroup);
      const needsWorkload = dayMuscleGroupNeedsWorkload(muscleGroup, exercisesForGroup);

      result = {
        dayMuscleGroup: muscleGroup,
        needsJointPainDex,
        needsSoreness,
        needsPump,
        needsWorkload,
      };

      break;
    }
  }

  return result;
}

export function getLatestBodyweight(mesoWeeks) {
  const latestBodyweight = mesoWeeks
    .map(week =>
      week.days.map(day => ({
        bodyweight: day.bodyweight,
        bodyweightAt: day.bodyweightAt,
        unit: day.unit,
      }))
    )
    .flat()
    .sort((a, b) => b.bodyweightAt - a.bodyweightAt)?.[0];

  return latestBodyweight
    ? latestBodyweight
    : {
        bodyweight: null,
        bodyweightAt: null,
        unit: null,
      };
}

export function isBlockedDeloadWorkout(dayBlobject, mesoBlobject) {
  return (
    dayBlobject.week === mesoBlobject.weeks.length - 1 &&
    !mesoBlobject.weeks[dayBlobject.week - 1].days.every(d => d.finishedAt)
  );
}

export function decodeMesoRir(encodedMicroRirs, display = false) {
  if (!isNum(encodedMicroRirs)) {
    throw new ErrorWithExtraData('argument is not a number', {encodedMicroRirs});
  }

  const rirStringsArray = Array.from(String(encodedMicroRirs));
  if (display) {
    return rirStringsArray;
  }

  return rirStringsArray.map(rirString => {
    const num = Number(rirString);
    return num === 0 ? num : num * -1;
  });
}

export function encodeMesoRir(rirArray) {
  if (!Array.isArray(rirArray)) {
    throw new ErrorWithExtraData('argument is not an array', {rirArray});
  }
  if (!rirArray.every(t => isNum(Number(t)))) {
    throw new ErrorWithExtraData('array member is not a number', {rirArray});
  }

  return Number(rirArray.map(t => Math.abs(t)).join(''));
}

export function isSetFinished(set) {
  return !!set && set.finishedAt !== null;
}

export function isSetComplete(set) {
  // A set is considered "complete" if it has a finishedAt stamp, and isn't skipped
  return isSetFinished(set) && set.reps !== SKIPPED_SET_REPS;
}

function _isWeekAtLeastStarted(weekDays) {
  for (const day of weekDays) {
    if (
      STATUSES_IN_PROGRESS.day.includes(day.status) ||
      STATUSES_FINISHED.day.includes(day.status)
    ) {
      return true;
    }
  }

  return false;
}

export function getFirstUnstartedWeekIdx(mesoWeeks) {
  for (const [weekIdx, week] of mesoWeeks.entries()) {
    if (!_isWeekAtLeastStarted(week.days)) {
      return weekIdx;
    }
  }

  return null;
}

export function getCanAddWeek(mesoMicrosCount, firstUnstartedWeekIdx) {
  if (mesoMicrosCount > MAX_MESO_MICROS) {
    throw new ErrorWithExtraData('SEE YOU IN THE LOGS BITCH!', {
      mesoMicrosCount,
      firstUnstartedWeekIdx,
    });
  }

  // cannot go above maximum micros
  if (mesoMicrosCount === MAX_MESO_MICROS) {
    return false;
  }

  // cannot add week, deload has started workouts
  if (!isNum(firstUnstartedWeekIdx)) {
    return false;
  }

  return true;
}

export function getCanRemoveWeek(mesoMicrosCount, firstUnstartedWeekIdx) {
  if (mesoMicrosCount < MIN_MESO_MICROS) {
    throw new ErrorWithExtraData('SEE YOU IN THE LOGS BITCH!', {
      mesoMicrosCount,
      firstUnstartedWeekIdx,
    });
  }

  if (!isNum(firstUnstartedWeekIdx)) {
    return false;
  }

  // cannot go below minimum micros
  if (mesoMicrosCount === MIN_MESO_MICROS) {
    return false;
  }

  // Cannot remove a week unless there is at least 1 unstarted accumulation week
  if (firstUnstartedWeekIdx > mesoMicrosCount - 2) {
    return false;
  }

  return true;
}

// Used to determine whether a day muscle group should be reprogrammed after a user deletes a day exercise
export function shouldReprogramWorkoutMuscleGroup(dayBlobject, dexBlobject) {
  if (
    !dexBlobject ||
    !dexBlobject.id ||
    !dexBlobject.muscleGroupId ||
    !dayBlobject ||
    !dayBlobject.exercises.find(e => e.id === dexBlobject.id)
  ) {
    throw new ErrorWithExtraData('malformed arguments', {
      dayBlobject,
      dexBlobject,
    });
  }

  // Gather all other dexes for this muscle group
  const otherDexesForMuscleGroup = dayBlobject.exercises.filter(
    dfmg => dfmg.muscleGroupId === dexBlobject.muscleGroupId && dfmg.id !== dexBlobject.id
  );

  // Count up sets from all other dexes for this muscle group
  const otherDexesForMuscleGroupSetCount = otherDexesForMuscleGroup.flatMap(dex => dex.sets).length;

  return otherDexesForMuscleGroup.length && otherDexesForMuscleGroupSetCount < 1;
}
